import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Text from '../../components/Text'
// import Image from '../../components/Image'
import Button from '../../components/Button'
import { responsive } from '../../components/ThemeProvider/theme'
import ContactModal from '../../components/ContactModal'

import Layout from '../Layout'

// import banner from './banner.png'
// import monopoly from './monopoly.png'
import Triangle from '../../components/Triangle'

const skills = [
  {
    img: <StaticImage src="skill.png" layout="fullWidth" />,
  },
  {
    img: <StaticImage src="check.png" layout="fullWidth" />,
    title: '「檢查來路不明的數據，避開數據陷阱」',
    content: '不論你是在看新聞、滑臉書看到數據出現或是自己在做研究、蒐集資料需要用到數據都可以拿出這份Checklist\n檢查數據中有沒有隱藏的陷阱！',
  },
  {
    img: <StaticImage src="checklist.png" layout="fullWidth" />,
    title: '重新認識數據\n一起成為懂看、懂用的懂數好朋友！',
    content: '資訊爆炸的時代，數據充滿我們生活各個角落\n正因如此，培養數據識讀力愈來愈重要！\n數據會說話，但你需要好好理解才能真正聽懂\n重新認識數據，讓數據成為你的好朋友！檢查數據中有沒有隱藏的陷阱！',
  },
]

const DataGoodFriend = () => {
  return (
    <Layout footerBg="white" title="數據好朋友">
      <Box pb="0.5em" bg="custom.skyBlue">
        <Box maxWidth="740px" mx="auto">
          <StaticImage src="./banner.png" alt="我想當你的好朋友" layout="fullWidth" />
          <Box height="3em" />
        </Box>
      </Box>
      <Box.Relative
        color="white"
        px={responsive('2.5em', 0)}
        pt={responsive('4em', '9em')}
        pb="0.5em"
        bg="custom.darkBlue"
      >
        <Triangle borderColor="#3C9BD6 transparent transparent transparent" />
        <Box maxWidth="420px" mx="auto">
          <Text.Bold fontSize={responsive('1em', '1.5em')}>嘿！你聽過數據會說話嗎？</Text.Bold>
          <Text.S mt="1rem">
            看到這麼多人誤解數據<br />
            愛數的我們決定整理一份成為數據好朋友的指南<br />
            幫助數據在不同狀況下，能和更多人好好相處！<br />
            <br />
            擁有一份數據識讀指南，你也可以和數據當好朋友！
          </Text.S>
          <Flex flexDirection="column" textAlign="center" mt="1.25em">
            <Button
              bg="custom.skyBlue"
              color="white"
              borderColor="white"
              fontSize={responsive('1rem', '1.375rem')}
              _hover={{
                bg: 'custom.skyBlue',
                color: 'white'
              }}
              to="/souvenir/#數據識讀指南"
            >
              取得指南
            </Button>
            {/* <Button
              mt={responsive('0.5rem', '1em')}
              fontSize={responsive('1rem', '1.375rem')}
              color="custom.skyBlue"
              borderColor="custom.skyBlue"
              _hover={{
                color: 'custom.skyBlue'
              }}
            >
              完整了解計畫
            </Button> */}
          </Flex>
        </Box>
      </Box.Relative>
      <Box.Relative
        color="white"
        px={responsive('2.5em', 0)}
        pt={responsive('4em', '8.5em')}
        pb="0.5em"
        bg="custom.skyBlue"
      >
        <Triangle borderColor="#192D60 transparent transparent transparent" />
        <Box maxWidth="700px" mx="auto">
          <Box pl={responsive(0, '9em')}>
            <Text.Bold fontSize={responsive('1em', '1.25em')} color="custom.darkBlue">
              既然數據會說話，<br />
              不如聽聽數據的自我介紹<br />
              搞不好你也會想成為數據的好朋友！
            </Text.Bold>
          </Box>
          <Box mt="1.5em">
            <StaticImage src="./monopoly.png" alt="數據好朋友流程圖" layout="fullWidth" />
          </Box>
          <Box pl={responsive(0, '9em')}>
            <Text.Bold
              fontSize={responsive('1em', '1.25em')}
              mt={responsive('2.125em', '4rem')}
              color="custom.textYellow"
            >「數據不是最終的答案，而是找出問題的開始」</Text.Bold>
            <Text.S color="custom.darkBlue" mt={responsive('0.75rem', '1.5rem')}>
              看懂數據有一套專屬的流程<br />
              就像玩大富翁一樣<br />
              跟著數據一步一步回答自己的問題<br />
              雖然不一定能找到答案<br />
              但在過程中發現更好的問題，才是最有價值的！
            </Text.S>
            <Text.Bold
              mt={responsive('1.25em', '2.5rem')}
              fontSize={responsive('1em', '1.25em')}
              color="custom.textYellow"
            >「用對方法，人人都能是數據好朋友！」</Text.Bold>
            <Text.S color="custom.darkBlue" mt={responsive('0.75rem', '1.5rem')}>
              和數據相處有幾個重要的眉角<br />
              不用大費周章！也不需要是專家！<br />
              只要照著技巧攻略見招拆招<br />
              每個人都能成為數據好朋友
            </Text.S>
          </Box>
        </Box>
      </Box.Relative>
      <Box.Relative bg="custom.darkBlue" pt={responsive('4em', '10.75em')} pb={responsive('3em', '6.75em')} px="0.75em">
        <Triangle borderColor="#3C9BD6 transparent transparent transparent" />
        <Box maxWidth="576px" mx="auto">
          {skills.map(({ img, title, content }, i) => (
            <Box
              bg="custom.skyBlue"
              px={responsive('1.75em', '4em')}
              py={responsive('1.375em', '1.5rem')}
              mt={responsive('1em', '2.5em')}
              borderRadius="2.5em"
              key={i}
            >
              <Box>
                {img}
              </Box>
              <Text.Bold mt={title && responsive('1em', '1.5em')} fontSize={responsive('1em', '1.25em')} color="custom.textYellow">
                {title}
              </Text.Bold>
              <Text.S whiteSpace="pre-wrap" color="custom.darkBlue" mt={content && responsive('0.5rem', '1.5rem')}>
                {content}
              </Text.S>
            </Box>
          ))}
        </Box>
        <Flex flexDirection="column" alignItems="center" mt={responsive('3em', '3.5em')}>
          <Button
            bg="custom.skyBlue"
            fontSize={responsive('1rem', '1.375rem')}
            color="white"
            borderColor="white"
            _hover={{
              bg: 'custom.skyBlue',
              color: 'white'
            }}
            to="/souvenir/#數據識讀指南"
          >
            贊助獲得完整版數據好朋友
          </Button>
          <Button
            mt={responsive('0.5rem', '1em')}
            fontSize={responsive('1rem', '1.375rem')}
            color="custom.skyBlue"
            borderColor="custom.skyBlue"
            _hover={{
              color: 'custom.skyBlue'
            }}
            as={ContactModal.Button}
          >
            教育或各種合作歡迎與我們聊聊
          </Button>
        </Flex>
      </Box.Relative>
    </Layout>
  )
}

export default DataGoodFriend
