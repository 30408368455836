import React from 'react'
import DataGoodFriend from '../../containers/DataGoodFriend'

const datafriend = () => {
  return (
    <DataGoodFriend />
  )
}

export default datafriend
