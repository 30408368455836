import React from 'react'

import Box from './Box'
import { responsive } from './ThemeProvider/theme'

const Triangle = (props) => {
  return (
    <Box.Absolute
      top="0"
      left="0"
      borderWidth={responsive('30px 50vw 0 50vw', '90px 50vw 0 50vw')}
      borderColor="white transparent transparent transparent"
      {...props}
    />
  )
}

export default Triangle
